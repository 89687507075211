<script setup>
import UiButton from '@/components/ui/button/UiButton.vue';
import FormWrapper from '@/components/layout/forms/FormWrapper.vue';

const props = defineProps({
  componentData: {
    type: Object,
    default: () => ({}),
  },
});
</script>
<template>
  <FormWrapper
    class="status-form"
    :heading="props.componentData?.heading"
    :description="props.componentData.description"
  >
    <template #content>
      <div v-if="props.componentData?.buttonData" class="status-form__actions">
        <UiButton
          v-for="(item, index) in props.componentData.buttonData"
          :key="index"
          class="status-form__button"
          :text="item.text"
          :theme="item.theme"
          @click="item?.action"
        />
      </div>
    </template>
  </FormWrapper>
</template>
<style scoped lang="scss">
.status-form {
  &__actions {
    display: flex;
    align-items: center;
    gap: em(16);
    margin-top: em(48);

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &__button {
    width: calc(50% - #{em(8)});

    @include media-breakpoint-down(sm) {
      width: auto;
      min-width: em(240);
    }
  }
}
</style>
